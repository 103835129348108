
import { createSlice } from '@reduxjs/toolkit';

const presentDate= new Date()

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        dates: [presentDate, presentDate],
        security:"",
        exchange:"ZEN_EXCHANGE",
        strategy:"",
        portfolio:"",
        entity:"",
        broker:"",
        userList : [],
        orderStrategyId : 1,
        strategyList: {
            1:"One",
            11000: "2PM_STRATEGY" , 
            11001: "2PM_STRATEGY_TEST1", 
            11002: "2PM_STRATEGY_TEST2", 
            11003: "2PM_STRATEGY_TEST3"
          }

    },
    reducers: {
        saveDates: {
            reducer: (state, action) => {
                state.dates = action.payload.dates;
            },
            prepare: (dates) => ({ payload: { dates } }),
        },
        saveSecurity: {
            reducer: (state, action) => {
                state.security = action.payload.security;
            },
            prepare: (security) => ({ payload: { security } })
        },
        saveExchange: {
            reducer: (state, action) => {
                state.exchange = action.payload.exchange;
            },
            prepare: (exchange) => ({ payload: { exchange} })
        },
        saveStrategy: {
            reducer: (state, action) => {
                state.strategy= action.payload.strategy;
            },
            prepare: (strategy) => ({ payload: { strategy } })
        },
        savePortfolio:{
            reducer: (state, action) => {
                state.portfolio = action.payload.portfolio;
            },
            prepare: (portfolio) => ({ payload: { portfolio } })
        },
        saveEntity:{
            reducer: (state, action) => {
                state.entity = action.payload.entity
            },
            prepare: (entity) => ({ payload: {entity} })
        },
        saveBroker:{
            reducer: (state, action) => {
                state.broker = action.payload.broker;
            },
            prepare: (broker) => ({ payload: { broker} })
        },
        setUserList(state, action){
            state.userList = action.payload
        },
        setOrderStrategyId(state, action){
            state.orderStrategyId = action.payload
        }

    },
});

export const { saveDates,saveSecurity,saveExchange,saveStrategy,saveBroker,saveEntity,savePortfolio, setUserList, setOrderStrategyId } = filterSlice.actions;

export default filterSlice.reducer;
